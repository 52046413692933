<template>
  <div class="content-inner">
    <div v-if="location.data.top" class="content-top">
      <!-- Content Top Location-->
      <component :is="location.data.top.component" :location="location.data.top"></component>

      <!-- Blocks -->
      <template v-for="childBlock in location.data.top.blocks">
        <component :is="childBlock.component" :block="childBlock" :key="'content-top-' + childBlock.id"></component>
      </template>
    </div>

    <aside v-if="location.data.left" class="sidebar-left">
      <!-- Left Sidebar Location-->
      <keep-alive>
        <component :is="location.data.left.component" :location="location.data.left" v-on="$listeners"></component>
      </keep-alive>
      <!-- Blocks -->
      <template v-for="childBlock in location.data.left.blocks">
        <keep-alive :key="'sidebar-left-' + childBlock.id">
          <component :is="childBlock.component" :block="childBlock" v-on="$listeners"></component>
        </keep-alive>
      </template>
    </aside>

    <main v-if="location.data.main" class="content-main">
      <!-- Main Location-->
      <component :is="location.data.main.component" :location="location.data.main" v-on="$listeners"></component>

      <!-- Blocks -->
      <template v-for="childBlock in location.data.main.blocks">
        <component :is="childBlock.component" :block="childBlock" :key="'content-main-' + childBlock.id" v-on="$listeners"></component>
      </template>
    </main>

    <aside v-if="location.data.right" class="sidebar-right">
      <!-- Right Sidebar Location-->
      <keep-alive>
        <component :is="location.data.right.component" :location="location.data.right" v-on="$listeners"></component>
      </keep-alive>
      <!-- Blocks -->
      <template v-for="childBlock in location.data.right.blocks">
        <keep-alive :key="'sidebar-right-' + childBlock.id">
          <component :is="childBlock.component" :block="childBlock" v-on="$listeners"></component>
        </keep-alive>
      </template>
    </aside>

    <div v-if="location.data.bottom" class="content-bottom">
      <!-- Content Bottom Location-->
      <component :is="location.data.bottom.component" :location="location.data.bottom" v-on="$listeners"></component>

      <!-- Blocks -->
      <template v-for="childBlock in location.data.bottom.blocks">
        <component :is="childBlock.component" :block="childBlock" :key="'content-bottom-' + childBlock.id" v-on="$listeners"></component>
      </template>
    </div>
  </div>
</template>

<style>
.content-inner {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
  grid-auto-flow: row;
  width: 100%;
  max-width: 1560px;
}
.content-top,
.content-bottom {
  grid-column: 1/-1;
}
.sidebar-left,
.sidebar-right {
  width: 300px;
  max-width: 300px;
}
.sidebar-left {
  margin-right: 10px;
}
.sidebar-right {
  margin-left: 10px;
}
.sidebar-right > * {
  margin-bottom: 10px;
}
@media (max-width: 1280px) {
  .content-inner {
    grid-template-columns: 1fr;
    padding: 0 0.2rem;
  }
  .sidebar-left {
    margin-left: 10px;
  }
  .sidebar-right {
    margin-right: 10px;
  }
}

@media (max-width: 960px) {
  .sidebar-left,
  .sidebar-right {
    width: 100%;
    max-width: unset;
    margin: 0;
  }
}

/* Table of Contents */
.article-outline {
  background-color: #f9f9f9;
  padding: 1rem;
}
.article-outline .nav,
.article-outline .nav-item {
  margin: 0;
}
.article-outline .nav-item > a {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}
.article-outline .nav-item > a:hover {
  overflow: visible;
}
.article-outline .nav-item a:hover {
  background-color: rgb(225, 225, 225);
}
.article-outline .heading > a {
  display: block;
  padding: 0.5rem;
}
.article-outline .heading:not(:last-of-type) > a {
  border-bottom: 1px solid rgb(225, 225, 225);
}
.article-outline .heading:not(:first-of-type) > a {
  border-top: 1px solid rgb(255, 255, 255);
}
.article-outline .subheading > a {
  margin: 0;
  padding: 0.5rem;
  padding-left: 1.5rem;
  border-top: 1px solid rgb(255, 255, 255);
  border-bottom: 1px solid rgb(225, 225, 225);
}
</style>

<script>
export default {
  name: 'defaultContent',
  props: {
    location: Object,
  },
  computed: {
    layout() {
      let layout = 'single';
      if ('left' in this.block.data && 'right' in this.block.data) {
        layout = 'full';
      } else if ('left' in this.block.data && !('right' in this.block.data)) {
        layout = 'left-only';
      } else if ('right' in this.block.data && !('left' in this.block.data)) {
        layout = 'right-only';
      }
      return layout;
    },
  },
  data() {
    return {};
  },
};
</script>
